import React from "react";

export default function HeroSection() {
  return (
    <div className="container w-full mx-auto px-[70px] py-[100px] ">
      <div className="w-full mx-auto lg:gap-0">
        <div className="lg:flex lg:items-center">
          <div className="z-10 md:pb-3">
            <h1 className="text-[40px] leading-[48px] font-semibold text-[#0D283E] md:text-[56px] md:leading-[67px]  flex items-start md:items-center flex-col md:flex-row">
              <span className="text-[42px] md:text-[56px] bg-clip-text text-transparent  bg-gradient-to-r from-blue-500 via-blue-300 to-green-500 inline-block pt-1.5 md:pt-0">
                Fetch.ai network
              </span>
            </h1>
            <div className="max-w-[626px]">
              <h2 className=" md:font-normal font-semibold pt-4 pb-10 text-[#0D283E] text-2xl text-[22px]">
                Explore information on Validators, the Fetch.ai wallet and FET
                coin.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
