import { Link } from "gatsby";
import React from "react";

const buttonStyles = {
  color: "#fff",
  padding: "16px 26px",
  borderRadius: "6px",
  minWidth: "149px",
  minHeight: "58px",
};

function PrimaryButton({
  label,
  action = null,
  href = null,
  classes = "",
  type = "button",
  isLoading = false,
  ...rest
}) {
  const labelEl = href ? <Link to={href}>{label}</Link> : label;
  return (
    <button
      className={`font-medium bg-gradient-to-r from-blue-500 to-cyan-400  capitalize ${classes}`}
      style={buttonStyles}
      onClick={action}
      type={type}
      {...rest}
    >
      {isLoading ? "Submitting..." : labelEl}
    </button>
  );
}

export default PrimaryButton;
