import React from "react";
import HeroSection from "../components/network/HeroSection";
import Layout from "../components/layout";
import CommunitySection from "../components/common/CommunitySection";
import Validators from "../components/network/Validators";
import { useBannerWidthContext } from "../context/BannerWidthContext";
import { useBannerDimensions } from "../hooks/useBannerDimensions";

function Network() {
  const { dispatch } = useBannerWidthContext();
  const bannerRef = useBannerDimensions(dispatch);
  return (
    <Layout headerClass="relative">
      <div className="overflow-hidden">
        <div ref={bannerRef}>
          <HeroSection />
        </div>
        <Validators />
      </div>
    </Layout>
  );
}
export default Network;
