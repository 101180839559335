import { useRef, useEffect } from "react";
import { BannerWidthReducerActions } from "../reducers/BannerWidthReducer";

export const useBannerDimensions = (dispatch) => {
  const bannerRef = useRef(null);

  const handleResize = () => {
    dispatch({
      type: BannerWidthReducerActions.SET_WIDTH,
      payload: bannerRef.current.offsetWidth,
    });
    dispatch({
      type: BannerWidthReducerActions.SET_HEIGHT,
      payload: bannerRef.current.offsetHeight,
    });
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      dispatch({ type: BannerWidthReducerActions.RESET });
    };
  }, [dispatch]);

  return bannerRef;
};
