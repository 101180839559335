import React from "react";
import PrimaryButton from "../atoms/PrimaryButton";
import validator from "../../images/validator.svg";
import toolBox from "../../images/toolBox.svg";
import greenGlow from "../../images/green_glow.svg";
import { useNewsLaterContext } from "../../context/NewsLaterContext";
import walletFet from "../../images/Wallet_FET.svg";

const Validators = () => {
  const { dispatch, actions } = useNewsLaterContext();

  return (
    <>
      <section className="py-[100px] container md:px-[120px] px-[20px]  mx-auto">
        <div className="flex flex-col items-center w-full gap-8 mx-auto lg:grid lg:grid-cols-2">
          <div className="w-full md:relative lg:w-3/4">
            <div className="relative items-center justify-center hidden lg:flex h-96">
              <img
                src={walletFet}
                className="object-cover w-full rounded-2xl"
                alt="mission 3"
              />
              <img
                src={greenGlow}
                className="absolute object-cover w-full rounded-2xl -top-40"
                alt="mission 3"
              />
            </div>
          </div>

          <div className=" flex flex-col justify-center z-10 relative md:mt-0 max-w-[460px] ">
            <h4 className="text-[40px] md:text-[46px] leading-12 text-center md:text-left tracking-normal mb-4 font-bold">
              Wallet &nbsp;
              <br className="block md:hidden" />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#4789CD] to-[#3CD7F6]">
                & FET coins
              </span>
            </h4>
            <div className="relative flex items-center justify-center h-96 lg:hidden">
              <img
                src={walletFet}
                className="object-cover w-full rounded-2xl"
                alt="mission 3"
              />
              <img
                src={greenGlow}
                className="absolute object-cover w-full rounded-2xl -top-40"
                alt="mission 3"
              />
            </div>
            <div className="mb-6 text-left">
              <p className="text-[16px]">
                A Web3 network run by community of validators where you can
                build, deploy and monetize your Decentralized Apps (DApps).
                <br />
                <br />
                Use FET to pay for the transactions on the network. And manage
                your FET balance using the Fetch Wallet.
              </p>
              <div className="pt-6 md:pt-[2.7rem] text-center md:text-left">
                <a
                  href="https://fetch.ai/get-fetch-wallet"
                  target="_blank"
                  rel="noreferrer"
                >
                  <PrimaryButton
                    classes="md:mr-[28px] mx-auto mb-4 md:w-auto w-full"
                    label="Get Wallet"
                  />
                </a>
                <a href="/fet-tokens" target="_blank">
                  <button className="font-semibold md:w-auto w-full rounded-[6px] border-[1px] border-solid border-[#3EA2DA] px-[32px] py-[16px] bg-transparent text-[16px] tracking-wider text-[#3EA2DA]">
                    Learn more about FET
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white ">
        <div className="md:pt-[100px] md:px-[120px] px-[20px] container mx-auto  md:pb-[180px] py-[50px] ">
          <div className="w-full items-center flex flex-col  lg:grid lg:grid-cols-2 md:gap-[120px]">
            <div className="relative z-10 flex flex-col justify-center md:mt-0 lg:w-auto">
              <div className="max-w-[460px] ">
                <h4 className="text-[46px] leading-12 text-center md:text-left tracking-normal mb-4 font-bold">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#4789CD] to-[#3CD7F6]">
                    Validators
                  </span>
                </h4>
                <div className="w-full lg:hidden md:relative">
                  <div className="relative flex items-center justify-center h-96">
                    <img
                      src={validator}
                      className="object-cover w-full rounded-2xl"
                      alt="mission 3"
                    />
                    <img
                      src={greenGlow}
                      className="absolute object-cover w-full rounded-2xl top-20"
                      alt="mission 3"
                    />
                  </div>
                </div>
                <div className="mb-6 text-left ">
                  <p className="text-[16px] ">
                    Community validators use their nodes to secure the network
                    and validate + record the transactions on it.
                    <br />
                    <br />
                    In return, validators get rewarded by earning FET as block
                    rewards and transaction fees. Validators can also offer
                    delegation service to small FET holders and earn a portion
                    of the delegators staking rewards.
                  </p>
                </div>
              </div>

              <div className="pt-6 md:pt-[2.7rem] text-center md:text-left ">
                <a
                  href="https://fetch.ai/docs/guides/fetch-network/ledger/setup-validator-node"
                  target="_blank"
                  rel="noreferrer"
                >
                  <PrimaryButton
                    classes="md:mr-[28px] mx-auto mb-4 md:mb-0 w-full md:w-auto"
                    label="Become a validator"
                  />
                </a>
              </div>
            </div>

            <div className="hidden w-full lg:block md:relative">
              <div className="relative flex items-center justify-center">
                <img
                  src={validator}
                  className="object-contain w-full rounded-2xl"
                  alt="mission 3"
                />
                <img
                  src={greenGlow}
                  className="absolute object-contain w-full rounded-2xl top-20"
                  alt="mission 3"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-[50px] container mx-auto px-[20px] md:py-[100px] md:px-[120px] px-[20px  mt-10">
        <div className="flex flex-col items-center w-full gap-8 mx-auto lg:grid lg:grid-cols-2">
          <div className="w-full md:relative lg:w-3/4">
            <div className="relative items-center justify-center hidden lg:flex h-96">
              <img
                src={toolBox}
                className="object-cover w-full rounded-2xl"
                alt="mission 3"
              />
              <img
                src={greenGlow}
                className="absolute object-cover w-full rounded-2xl -top-36 -left-32"
                alt="mission 3"
              />
            </div>
          </div>

          <div className=" flex flex-col justify-center z-10 relative md:mt-0 max-w-[460px] ">
            <h4 className="text-[46px] leading-12 text-center md:text-left tracking-normal mb-4 font-bold">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#4789CD] to-[#3CD7F6]">
                Tool Box
              </span>
            </h4>
            <div className="relative flex items-center justify-center lg:hidden h-96">
              <img
                src={toolBox}
                className="object-cover w-full rounded-2xl"
                alt="mission 3"
              />
              <img
                src={greenGlow}
                className="absolute object-cover w-full rounded-2xl -top-36 -left-32"
                alt="mission 3"
              />
            </div>
            <div className="mb-6 text-left">
              <div className="text-[16px]">
                Use a myriad of tools to build, deploy, and query the Fetch
                Network.
                <ul className="mt-2 ml-4 list-disc">
                  <li>
                    <a
                      href="https://explore-fetchhub.fetch.ai/"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      Block Explorer
                    </a>{" "}
                    - to query the network
                  </li>
                  <li>
                    <a
                      href="https://github.com/fetchai/cosmpy"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      Cosmpy
                    </a>{" "}
                    - a python library to interact with the network
                  </li>
                  <li>
                    <a
                      href="https://github.com/fetchai/fetchd"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      Fetchd
                    </a>{" "}
                    - a library to run a full node (library used by the
                    validators)
                  </li>
                  <li>
                    <a
                      href="https://github.com/fetchai/jenesis"
                      target="_blank"
                      className="underline"
                      rel="noreferrer"
                    >
                      Jenesis
                    </a>{" "}
                    - a python script to assist in smart contract development.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Validators;
